import { StoreCompany } from './store-company.component';
import { GET_COMPANIES_BY_PAGES_QUERY } from './queries';
import { CompanyType, GetCompaniesByPagesType } from './types';
import { EditCompany } from './edit-company.component';
import Table, { CustomColumnType } from '../table';
import { dumpCompanies } from 'src/helpers/dump.helper';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './Company.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import TableHeader from '../base/tableHeader';
import { CompanySearchEnun } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import PageHeader from '../base/pageHeader';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { dumpCompaniesSortParams } from 'src/utils/dump-sort-params.utils';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';

const COMPANIES_PAGINATION_LIMIT = 10;

export const CompaniesList = () => {
  const {
    setPageParams,
    setPaginationLimitParams,
    handleSetSearchParams,
    setSortParams,
    setSellsSearchParams,
    canClear,
    clearAllFilters,
    sellsSearch,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, loading, refetch, error, pageData, updateData } = useQueryHook<
    GetCompaniesByPagesType,
    CompanyType
  >({
    query: GET_COMPANIES_BY_PAGES_QUERY,
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: paginationLimit ? paginationLimit : COMPANIES_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...prepareSearchBySells(sellsSearch),
      ...dumpCompaniesSortParams({ field: sortField, order: sortOrder }),
    },
    dumpFunction: dumpCompanies,
  });

  const COLUMNS: CustomColumnType = [
    {
      key: 'companyName',
      dataIndex: 'companyName',
      title: (
        <TableHeader
          title={'Company name'}
          search
          key={CompanySearchEnun.NAME}
          name={CompanySearchEnun.NAME}
          searchValue={sellsSearch?.[CompanySearchEnun.NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '250px',
      render: (__: any, node?: CompanyType) => {
        return (
          <EditCompany
            className="fcrm-greed-col"
            companyData={{ ...node }}
            updateUserInList={updateData}
          />
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('companyName', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'creatorsCount',
      dataIndex: 'creatorsCount',
      title: (
        <TableHeader
          title={'Creators count'}
          search
          key={CompanySearchEnun.CREATOR_COUNT}
          name={CompanySearchEnun.CREATOR_COUNT}
          searchValue={sellsSearch?.[CompanySearchEnun.CREATOR_COUNT]}
          setSearch={setSellsSearchParams}
        />
      ),
      render: (__: any, node: CompanyType) => {
        return (
          <div className={styles.ellipsisText} style={{ width: '120px' }}>
            {node?.creatorsCount}
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('creatorsCount', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'employeesCount',
      dataIndex: 'employeesCount',
      title: 'Employee',
      render: (__: any, node: CompanyType) => {
        return (
          <div className={styles.ellipsisText} style={{ width: '80px' }}>
            {node?.employeesCount}
          </div>
        );
      },
    },
    {
      key: 'contactEmail',
      dataIndex: 'contactEmail',
      title: (
        <TableHeader
          title={'Contact email'}
          search
          key={CompanySearchEnun.CONTACT_EMAIL}
          name={CompanySearchEnun.CONTACT_EMAIL}
          searchValue={sellsSearch?.[CompanySearchEnun.CONTACT_EMAIL]}
          setSearch={setSellsSearchParams}
        />
      ),
      render: (__: any, node: CompanyType) => {
        return (
          <CopyableElementWrapper classname='w-[250px]'>
            {node?.contactEmail}
          </CopyableElementWrapper>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('contactEmail', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'telegramNumber',
      dataIndex: 'telegramNumber',
      title: (
        <TableHeader
          title={'Contact number'}
          search
          key={CompanySearchEnun.CONTACT_NUMBER}
          name={CompanySearchEnun.CONTACT_NUMBER}
          searchValue={sellsSearch?.[CompanySearchEnun.CONTACT_NUMBER]}
          setSearch={setSellsSearchParams}
        />
      ),
      render: (__: any, node: CompanyType) => {
        return (
          <CopyableElementWrapper classname='w-[200px]'>
            {node?.telegramNumber}
          </CopyableElementWrapper>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('telegramNumber', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'author',
      dataIndex: 'author',
      title: (
        <TableHeader
          title={'Author'}
          search
          key={CompanySearchEnun.AUTHOR}
          name={CompanySearchEnun.AUTHOR}
          searchValue={sellsSearch?.[CompanySearchEnun.AUTHOR]}
          setSearch={setSellsSearchParams}
        />
      ),
      render: (__: any, node: CompanyType) => (
        <div className={styles.ellipsisText} style={{ width: '200px' }}>
          {node?.author?.username || ''}
        </div>
      ),
      sorter: true,
      sortOrder: checkSortOrder('author', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'websiteUrl',
      dataIndex: 'websiteUrl',
      title: (
        <TableHeader
          title={'Website'}
          search
          key={CompanySearchEnun.WEBSITE}
          name={CompanySearchEnun.WEBSITE}
          searchValue={sellsSearch?.[CompanySearchEnun.WEBSITE]}
          setSearch={setSellsSearchParams}
        />
      ),
      render: (__: any, node: CompanyType) => {
        return (
          <CopyableElementWrapper classname='w-[250px]'>
            {node?.websiteUrl}
          </CopyableElementWrapper>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('websiteUrl', {
        field: sortField,
        order: sortOrder,
      }),
    },
    // {
    //   key: 'address',
    //   dataIndex: 'address',
    //   title: (
    //     <TableHeader
    //       title={'Address'}
    //       search
    //       key={CompanySearchEnun.WEBSITE}
    //       name={CompanySearchEnun.ADDRESS}
    //       searchValue={sellsSearch?.[CompanySearchEnun.ADDRESS]}
    //       setSearch={setSellsSearchParams}
    //     />
    //   ),
    //   render: (__: any, node: CompanyType) => {
    //     return (
    //       <div className={styles.ellipsisText} style={{ width: '200px' }}>
    //         {node?.address}
    //       </div>
    //     );
    //   },
    //   sorter: true,
    //   sortOrder: checkSortOrder('address', {
    //     field: sortField,
    //     order: sortOrder,
    //   }),
    // },
  ];

  return (
    <div className={styles.container}>
      <PageHeader
        title="Agencies"
        setSearch={handleSetSearchParams}
        search={searchText}
        clear={canClear}
        onClear={clearAllFilters}
      />

      <StoreCompany onCompanyStored={refetch} />

      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}

      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : COMPANIES_PAGINATION_LIMIT
          }
          defPageSize={COMPANIES_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          totalCount={pageData?.totalCount}
          emptyText="No agencies available."
        />
      </div>
    </div>
  );
};
